/* eslint-disable filenames/no-index */

import { lazyWithPreload } from './helpers';

export const AdminFeedsComparisonPage = lazyWithPreload(() =>
  import('~/pages/adminFeedsComparison/AdminFeedsComparisonPage').then(
    (res) => ({
      default: res.AdminFeedsComparisonPage,
    }),
  ),
);

export const AdminReviewsPage = lazyWithPreload(() =>
  import('~/pages/adminReviews/AdminReviewsPage').then((res) => ({
    default: res.AdminReviewsPage,
  })),
);

export const ApiKeysPage = lazyWithPreload(() =>
  import('~/pages/developers/ApiKeysPage').then((res) => ({
    default: res.ApiKeysPage,
  })),
);

export const ChannelPage = lazyWithPreload(() =>
  import('~/pages/channel/ChannelPage').then((res) => ({
    default: res.ChannelPage,
  })),
);

export const ChannelFeedPage = lazyWithPreload(() =>
  import('~/pages/channel/ChannelFeedPage').then((res) => ({
    default: res.ChannelFeedPage,
  })),
);

export const ChannelFollowersPage = lazyWithPreload(() =>
  import('~/pages/channelFollowers/ChannelFollowersPage').then((res) => ({
    default: res.ChannelFollowersPage,
  })),
);

export const ChannelMembersPage = lazyWithPreload(() =>
  import('~/pages/channelMembers/ChannelMembersPage').then((res) => ({
    default: res.ChannelMembersPage,
  })),
);

export const ChannelFollowersYouKnowPage = lazyWithPreload(() =>
  import('~/pages/channelFollowers/ChannelFollowersYouKnowPage').then(
    (res) => ({ default: res.ChannelFollowersYouKnowPage }),
  ),
);

export const CollectionOwnersPage = lazyWithPreload(() =>
  import('~/pages/collectionOwners/CollectionOwnersPage').then((res) => ({
    default: res.CollectionOwnersPage,
  })),
);

export const CollectionOwnersPageRightSideBarContent = lazyWithPreload(() =>
  import(
    '~/pages/collectionOwners/CollectionOwnersPageRightSideBarContent'
  ).then((res) => ({ default: res.CollectionOwnersPageRightSideBarContent })),
);

export const ConversationReactionsWithUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/conversationReactionsWithUsername/ConversationReactionsWithUsernamePage'
  ).then((res) => ({ default: res.ConversationReactionsWithUsernamePage })),
);

export const ConversationReactionsWithoutUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/conversationReactionsWithoutUsername/ConversationReactionsWithoutUsernamePage'
  ).then((res) => ({ default: res.ConversationReactionsWithoutUsernamePage })),
);

export const ConversationRecastsWithUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/conversationRecastsWithUsername/ConversationRecastsWithUsernamePage'
  ).then((res) => ({ default: res.ConversationRecastsWithUsernamePage })),
);

export const ConversationRecastsWithoutUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/conversationRecastsWithoutUsername/ConversationRecastsWithoutUsernamePage'
  ).then((res) => ({ default: res.ConversationRecastsWithoutUsernamePage })),
);

export const ConversationQuotesWithUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/conversationQuotesWithUsername/ConversationQuotesWithUsernamePage'
  ).then((res) => ({ default: res.ConversationQuotesWithUsernamePage })),
);

export const ConversationQuotesWithoutUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/conversationQuotesWithoutUsername/ConversationQuotesWithoutUsernamePage'
  ).then((res) => ({ default: res.ConversationQuotesWithoutUsernamePage })),
);

export const ConversationWithUsernamePage = lazyWithPreload(() =>
  import('~/pages/conversation/ConversationWithUsernamePage').then((res) => ({
    default: res.ConversationWithUsernamePage,
  })),
);

export const ConversationWithoutUsernamePage = lazyWithPreload(() =>
  import('~/pages/conversation/ConversationWithoutUsernamePage').then(
    (res) => ({ default: res.ConversationWithoutUsernamePage }),
  ),
);

export const DebugAdminTokenPage = lazyWithPreload(() =>
  import('~/pages/debug/DebugAdminTokenPage').then((res) => ({
    default: res.DebugAdminTokenPage,
  })),
);

export const DebugCastsPage = lazyWithPreload(() =>
  import('~/pages/debug/DebugCastsPage').then((res) => ({
    default: res.DebugCastsPage,
  })),
);

export const DebugMenuPage = lazyWithPreload(() =>
  import('~/pages/debug/DebugMenuPage').then((res) => ({
    default: res.DebugMenuPage,
  })),
);

export const DevelopersEmbedsPage = lazyWithPreload(() =>
  import('~/pages/developers/EmbedsPage').then((res) => ({
    default: res.DevelopersEmbedsPage,
  })),
);

export const DirectCastsInvitePage = lazyWithPreload(() =>
  import('~/pages/directCastsInvite/DirectCastsInvitePage').then((res) => ({
    default: res.DirectCastsInvitePage,
  })),
);

export const DirectCastsConversationPage = lazyWithPreload(() =>
  import('~/pages/directCastsConversation/DirectCastsConversationPage').then(
    (res) => ({ default: res.DirectCastsConversationPage }),
  ),
);

export const DirectCastsInboxPage = lazyWithPreload(() =>
  import('~/pages/directCastsInbox/DirectCastsInboxPage').then((res) => ({
    default: res.DirectCastsInboxPage,
  })),
);

export const DirectCastsInboxCreatePage = lazyWithPreload(() =>
  import('~/pages/directCastsInboxCreate/DirectCastsInboxCreatePage').then(
    (res) => ({
      default: res.DirectCastsInboxCreatePage,
    }),
  ),
);

export const DownloadPage = lazyWithPreload(() =>
  import('~/pages/download/DownloadPage').then((res) => ({
    default: res.DownloadPage,
  })),
);

export const MintPage = lazyWithPreload(() =>
  import('~/pages/mint/MintPage').then((res) => ({
    default: res.MintPage,
  })),
);

export const ChannelsPage = lazyWithPreload(() =>
  import('~/pages/explore/ChannelsPage').then((res) => ({
    default: res.ChannelsPage,
  })),
);

export const ManageChannelsForCategoryPage = lazyWithPreload(() =>
  import('~/pages/explore/ManageChannelsForCategoryPage').then((res) => ({
    default: res.ManageChannelsForCategoryPage,
  })),
);

export const ChannelJoinViaCodePage = lazyWithPreload(() =>
  import('~/pages/channelJoinViaCode/ChannelJoinViaCodePage').then((res) => ({
    default: res.ChannelJoinViaCodePage,
  })),
);

export const AppsPage = lazyWithPreload(() =>
  import('~/pages/explore/AppsPage').then((res) => ({
    default: res.AppsPage,
  })),
);

export const FollowersWithUsernamePage = lazyWithPreload(() =>
  import('~/pages/followersWithUsername/FollowersWithUsernamePage').then(
    (res) => ({ default: res.FollowersWithUsernamePage }),
  ),
);

export const FollowersWithoutUsernamePage = lazyWithPreload(() =>
  import('~/pages/followersWithoutUsername/FollowersWithoutUsernamePage').then(
    (res) => ({ default: res.FollowersWithoutUsernamePage }),
  ),
);

export const FollowersYouKnowWithUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/followersYouKnowWithUsername/FollowersYouKnowWithUsernamePage'
  ).then((res) => ({ default: res.FollowersYouKnowWithUsernamePage })),
);

export const FollowersYouKnowWithoutUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/followersYouKnowWithoutUsername/FollowersYouKnowWithoutUsernamePage'
  ).then((res) => ({ default: res.FollowersYouKnowWithoutUsernamePage })),
);

export const FollowingWithUsernamePage = lazyWithPreload(() =>
  import('~/pages/followingWithUsername/FollowingWithUsernamePage').then(
    (res) => ({ default: res.FollowingWithUsernamePage }),
  ),
);

export const FollowingWithoutUsernamePage = lazyWithPreload(() =>
  import('~/pages/followingWithoutUsername/FollowingWithoutUsernamePage').then(
    (res) => ({ default: res.FollowingWithoutUsernamePage }),
  ),
);

export const ComposePage = lazyWithPreload(() =>
  import('~/pages/homeFeed/ComposePage').then((res) => ({
    default: res.ComposePage,
  })),
);

export const HomeLandingPage = lazyWithPreload(() =>
  import('~/pages/homeLanding/HomeLandingPage').then((res) => ({
    default: res.HomeLandingPage,
  })),
);

export const HomeFeedPage = lazyWithPreload(() =>
  import('~/pages/homeFeed/HomeFeedPage').then((res) => ({
    default: res.HomeFeedPage,
  })),
);

export const HomeFeedPageContent = lazyWithPreload(() =>
  import('~/pages/homeFeed/HomeFeedPageContent').then((res) => ({
    default: res.HomeFeedPageContent,
  })),
);

export const BookmarksPage = lazyWithPreload(() =>
  import('~/pages/bookmarks/BookmarksPage').then((res) => ({
    default: res.BookmarksPage,
  })),
);

export const FramesPage = lazyWithPreload(() =>
  import('~/pages/frames/FramesPage').then((res) => ({
    default: res.FramesPage,
  })),
);

export const StarterPacksPage = lazyWithPreload(() =>
  import('~/pages/starterPacks/StarterPacksPage').then((res) => ({
    default: res.StarterPacksPage,
  })),
);

export const WarpsPage = lazyWithPreload(() =>
  import('~/pages/warps/WarpsPage').then((res) => ({
    default: res.WarpsPage,
  })),
);

export const CoinbaseWalletRegistrationPage = lazyWithPreload(() =>
  import('~/pages/coinbaseRegistration/CoinbaseWalletRegistrationPage').then(
    (res) => ({
      default: res.CoinbaseWalletRegistrationPage,
    }),
  ),
);

export const SignupForInvitePage = lazyWithPreload(() =>
  import('~/pages/signup/SignupForInvitePage').then((res) => ({
    default: res.SignupForInvitePage,
  })),
);

export const ClaimInvitePage = lazyWithPreload(() =>
  import('~/pages/signup/ClaimInvitePage').then((res) => ({
    default: res.ClaimInvitePage,
  })),
);

export const PaidInvitgePage = lazyWithPreload(() =>
  import('~/pages/signup/PaidInvitePage').then((res) => ({
    default: res.PaidInvitePage,
  })),
);

export const LocationUsersPage = lazyWithPreload(() =>
  import('~/pages/locationUsers/LocationUsersPage').then((res) => ({
    default: res.LocationUsersPage,
  })),
);

export const NotificationGroupAssetsPage = lazyWithPreload(() =>
  import('~/pages/notificationGroupAssets/NotificationGroupAssetsPage').then(
    (res) => ({ default: res.NotificationGroupAssetsPage }),
  ),
);

export const NotificationGroupUsersPage = lazyWithPreload(() =>
  import('~/pages/notificationGroupUsers/NotificationGroupUsersPage').then(
    (res) => ({ default: res.NotificationGroupUsersPage }),
  ),
);

export const NotificationGroupCastsPage = lazyWithPreload(() =>
  import('~/pages/notificationGroupCasts/NotificationGroupCastsPage').then(
    (res) => ({ default: res.NotificationGroupCastsPage }),
  ),
);

export const NotificationGroupChannelRoleInvitesPage = lazyWithPreload(() =>
  import(
    '~/pages/notificationGroupChannelRoleInvites/NotificationGroupChannelRoleInvitesPage'
  ).then((res) => ({ default: res.NotificationGroupChannelRoleInvitesPage })),
);

export const NotificationGroupFramesPage = lazyWithPreload(() =>
  import('~/pages/notificationGroupFrames/NotificationGroupFramesPage').then(
    (res) => ({ default: res.NotificationGroupFramesPage }),
  ),
);

export const NotificationsPage = lazyWithPreload(() =>
  import('~/pages/notifications/NotificationsPage').then((res) => ({
    default: res.NotificationsPage,
  })),
);

export const ProfileCastsAndRepliesWithUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/profileCastsAndRepliesWithUsername/ProfileCastsAndRepliesWithUsernamePage'
  ).then((res) => ({ default: res.ProfileCastsAndRepliesWithUsernamePage })),
);

export const ProfileCastsAndRepliesWithoutUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/profileCastsAndRepliesWithoutUsername/ProfileCastsAndRepliesWithoutUsernamePage'
  ).then((res) => ({ default: res.ProfileCastsAndRepliesWithoutUsernamePage })),
);

export const ProfileCastsWithUsernamePage = lazyWithPreload(() =>
  import('~/pages/profileCastsWithUsername/ProfileCastsWithUsernamePage').then(
    (res) => ({ default: res.ProfileCastsWithUsernamePage }),
  ),
);

export const ProfileCastsWithoutUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/profileCastsWithoutUsername/ProfileCastsWithoutUsernamePage'
  ).then((res) => ({ default: res.ProfileCastsWithoutUsernamePage })),
);

export const ProfileLikesWithUsernamePage = lazyWithPreload(() =>
  import('~/pages/profileLikesWithUsername/ProfileLikesWithUsernamePage').then(
    (res) => ({ default: res.ProfileLikesWithUsernamePage }),
  ),
);

export const ProfileLikesWithoutUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/profileLikesWithoutUsername/ProfileLikesWithoutUsernamePage'
  ).then((res) => ({ default: res.ProfileLikesWithoutUsernamePage })),
);

export const ProfileStarterPacksWithUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/profileStarterPacksWithUsername/ProfileStarterPacksWithUsernamePage'
  ).then((res) => ({ default: res.ProfileStarterPacksWithUsernamePage })),
);

export const ProfileStarterPacksWithoutUsernamePage = lazyWithPreload(() =>
  import(
    '~/pages/profileStarterPacksWithoutUsername/ProfileStarterPacksWithoutUsernamePage'
  ).then((res) => ({ default: res.ProfileStarterPacksWithoutUsernamePage })),
);

export const MagicLinkPage = lazyWithPreload(() =>
  import('~/pages/magicLink/MagicLinkPage').then((res) => ({
    default: res.MagicLinkPage,
  })),
);

export const RecoveryPage = lazyWithPreload(() =>
  import('~/pages/recovery/RecoveryPage').then((res) => ({
    default: res.RecoveryPage,
  })),
);

export const RecoveryStartPage = lazyWithPreload(() =>
  import('~/pages/recoveryStart/RecoveryStartPage').then((res) => ({
    default: res.RecoveryStartPage,
  })),
);

export const RecoveryInitiatePage = lazyWithPreload(() =>
  import('~/pages/recoveryInitiate/RecoveryInitiatePage').then((res) => ({
    default: res.RecoveryInitiatePage,
  })),
);

export const TopSearchPage = lazyWithPreload(() =>
  import('~/pages/search/TopSearchPage').then((res) => ({
    default: res.TopSearchPage,
  })),
);

export const RecentSearchPage = lazyWithPreload(() =>
  import('~/pages/search/RecentSearchPage').then((res) => ({
    default: res.RecentSearchPage,
  })),
);

export const ChannelsSearchPage = lazyWithPreload(() =>
  import('~/pages/search/ChannelsSearchPage').then((res) => ({
    default: res.ChannelsSearchPage,
  })),
);

export const UsersSearchPage = lazyWithPreload(() =>
  import('~/pages/search/UsersSearchPage').then((res) => ({
    default: res.UsersSearchPage,
  })),
);

export const FrameValidatorPage = lazyWithPreload(() =>
  import('~/pages/developers/FrameValidatorPage').then((res) => ({
    default: res.FrameValidatorPage,
  })),
);

export const FrameDevelopersPage = lazyWithPreload(() =>
  import('~/pages/developers/FrameDeveloperPage').then((res) => ({
    default: res.FrameDeveloperPage,
  })),
);

export const SignInWithFarcasterPage = lazyWithPreload(() =>
  import('~/pages/signInWithFarcaster/SignInWithFarcasterPage').then((res) => ({
    default: res.SignInWithFarcasterPage,
  })),
);

export const SettingsAdvancedPage = lazyWithPreload(() =>
  import('~/pages/settingsAdvanced/SettingsAdvancedPage').then((res) => ({
    default: res.SettingsAdvancedPage,
  })),
);

export const SettingsConnectedAddressesPage = lazyWithPreload(() =>
  import(
    '~/pages/settingsConnectedAddresses/SettingsConnectedAddressesPage'
  ).then((res) => ({ default: res.SettingsConnectedAddressesPage })),
);

export const SettingsConnectedAccountsPage = lazyWithPreload(() =>
  import(
    '~/pages/settingsConnectedAccounts/SettingsConnectedAccountsPage'
  ).then((res) => ({ default: res.SettingsConnectedAccountsPage })),
);

export const SettingsDirectCastsPage = lazyWithPreload(() =>
  import('~/pages/settingsDirectCasts/SettingsDirectCastsPage').then((res) => ({
    default: res.SettingsDirectCastsPage,
  })),
);

export const SettingsDirectCastsRecommendedPage = lazyWithPreload(() =>
  import('~/pages/settingsDirectCasts/SettingsDirectCastsRecommendedPage').then(
    (res) => ({
      default: res.SettingsDirectCastsRecommendedPage,
    }),
  ),
);

export const SettingsDirectCastsOthersPage = lazyWithPreload(() =>
  import('~/pages/settingsDirectCasts/SettingsDirectCastsOthersPage').then(
    (res) => ({
      default: res.SettingsDirectCastsOthersPage,
    }),
  ),
);

export const SettingsActionsPage = lazyWithPreload(() =>
  import('~/pages/settingsActions/SettingsActionsPage').then((res) => ({
    default: res.SettingsActionsPage,
  })),
);

export const SettingsCastsAndUsersPage = lazyWithPreload(() =>
  import('~/pages/settingsCastsAndUsers/SettingsCastsAndUsersPage').then(
    (res) => ({
      default: res.SettingsCastsAndUsersPage,
    }),
  ),
);

export const SettingsMutedKeywordsPage = lazyWithPreload(() =>
  import('~/pages/settingsMutedKeywords/SettingsMutedKeywordsPage').then(
    (res) => ({
      default: res.SettingsMutedKeywordsPage,
    }),
  ),
);

export const SettingsStarterPacksPage = lazyWithPreload(() =>
  import('~/pages/settingsStarterPacks/SettingsStarterPacksPage').then(
    (res) => ({
      default: res.SettingsStarterPacksPage,
    }),
  ),
);

export const StarterPackPage = lazyWithPreload(() =>
  import('~/pages/starterPack/StarterPackPage').then((res) => ({
    default: res.StarterPackPage,
  })),
);

export const SettingsNotificationsPage = lazyWithPreload(() =>
  import('~/pages/settingsNotifications/SettingsNotificationsPage').then(
    (res) => ({ default: res.SettingsNotificationsPage }),
  ),
);

export const SettingsProfilePage = lazyWithPreload(() =>
  import('~/pages/settingsProfile/SettingsProfilePage').then((res) => ({
    default: res.SettingsProfilePage,
  })),
);

export const TrendingPage = lazyWithPreload(() =>
  import('~/pages/trending/TrendingPage').then((res) => ({
    default: res.TrendingPage,
  })),
);

export const FollowingPage = lazyWithPreload(() =>
  import('~/pages/following/FollowingPage').then((res) => ({
    default: res.FollowingPage,
  })),
);

export const UsersForYouPage = lazyWithPreload(() =>
  import('~/pages/usersForYou/UsersForYouPage').then((res) => ({
    default: res.UsersForYouPage,
  })),
);

export const SupportPage = lazyWithPreload(() =>
  import('~/pages/support/SupportPage').then((res) => ({
    default: res.SupportPage,
  })),
);

export const DevelopersPage = lazyWithPreload(() =>
  import('~/pages/developers/DevelopersPage').then((res) => ({
    default: res.DevelopersPage,
  })),
);

export const TermsOfUsePage = lazyWithPreload(() =>
  import('~/pages/termsOfUse/TermsOfUsePage').then((res) => ({
    default: res.TermsOfUsePage,
  })),
);

export const PrivacyPolicyPage = lazyWithPreload(() =>
  import('~/pages/privacyPolicy/PrivacyPolicyPage').then((res) => ({
    default: res.PrivacyPolicyPage,
  })),
);

export const GlobalFrameAnalyticsPage = lazyWithPreload(() =>
  import('~/pages/globalFrameAnalytics/GlobalFrameAnalyticsPage').then(
    (res) => ({
      default: res.GlobalFrameAnalyticsPage,
    }),
  ),
);

export const AddCastActionPage = lazyWithPreload(() =>
  import('~/pages/castActions/AddCastActionPage').then((res) => ({
    default: res.AddCastActionPage,
  })),
);

export const DiscoverActionsPage = lazyWithPreload(() =>
  import('~/pages/castActions/DiscoverActionsPage').then((res) => ({
    default: res.DiscoverActionsPage,
  })),
);

export const CastActionPlaygroundPage = lazyWithPreload(() =>
  import('~/pages/developers/CastActionPlaygroundPage').then((res) => ({
    default: res.CastActionPlaygroundPage,
  })),
);

export const ComposerActionPlaygroundPage = lazyWithPreload(() =>
  import('~/pages/developers/ComposerActionPlaygroundPage').then((res) => ({
    default: res.ComposerActionPlaygroundPage,
  })),
);

export const LaunchFramePage = lazyWithPreload(() =>
  import('~/pages/launchFrame/LaunchFramePage').then((res) => ({
    default: res.LaunchFramePage,
  })),
);

export const LaunchComposerActionPage = lazyWithPreload(() =>
  import('~/pages/composerActions/LaunchComposerActionPage').then((res) => ({
    default: res.LaunchComposerActionPage,
  })),
);

export const SettingsFramesPage = lazyWithPreload(() =>
  import('~/pages/settingsFrames/SettingsFramesPage').then((res) => ({
    default: res.SettingsFramesPage,
  })),
);

export const TokenPage = lazyWithPreload(() =>
  import('~/pages/token/TokenPage').then((res) => ({
    default: res.TokenPage,
  })),
);
