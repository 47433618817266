import { ArrowRightIcon, GearIcon, GlobeIcon } from '@primer/octicons-react';
import cn from 'classnames';
import { ApiFrame } from 'farcaster-client-data';
import {
  frameKeyExtractor,
  SharedAmpEvent,
  useFavoriteFrames,
} from 'farcaster-client-hooks';
import React, { FC, memo, Suspense, useMemo } from 'react';

import { FrameImageIcon } from '~/components/icons/FrameImageIcon';
import { Image } from '~/components/images/Image';
import { DefaultEmptyListView } from '~/components/lists/DefaultEmptyListView';
import { useAnalytics } from '~/contexts/AnalyticsProvider';
import { AppFrameProvider, useLaunchFrame } from '~/contexts/AppFrameProvider';
import { useCurrentRoute } from '~/hooks/navigation/useCurrentRoute';
import { useNavigate } from '~/hooks/navigation/useNavigate';

interface FrameTileProps {
  frame: ApiFrame;
  variant?: 'default' | 'large';
}

export const FrameTile: FC<FrameTileProps> = memo(
  ({ frame, variant = 'default' }) => {
    const { launchFrame } = useLaunchFrame();
    const [imageError, setImageError] = React.useState(false);

    const handleClick = () => {
      launchFrame({
        context: {
          type: 'launcher',
        },
        launchConfig: {
          type: 'standalone',
          name: frame.name,
          url: frame.homeUrl,
          splashImageUrl: frame.splashImageUrl,
          splashBackgroundColor: frame.splashBackgroundColor,
          author: frame.author,
        },
      });
    };

    const isLarge = variant === 'large';
    const size = isLarge ? 'w-[96px]' : 'w-[72px]';
    const padding = isLarge ? 'px-3 py-2' : 'p-2';
    const imageSize = isLarge ? 'h-14 w-14' : 'h-14 w-14';
    const textMargin = isLarge ? 'mt-3' : 'mt-1';
    const textSize = isLarge ? 'text-sm' : 'text-xs';

    return (
      <div
        className={cn(
          'flex cursor-pointer flex-col items-center rounded-lg hover:bg-hover',
          size,
          padding,
        )}
        onClick={handleClick}
      >
        <div
          className={cn(
            'border-hairline relative rounded-[11.2px] border bg-overlay-faint border-faint',
            imageSize,
          )}
        >
          {!imageError ? (
            <Image
              src={frame.iconUrl}
              alt={frame.name}
              className="h-full w-full rounded-[11.2px] object-cover"
              onError={() => setImageError(true)}
            />
          ) : (
            <div className="flex h-full w-full items-center justify-center">
              <FrameImageIcon size={32} />
            </div>
          )}
        </div>
        <div
          className={cn('w-full truncate text-center', textMargin, textSize)}
        >
          {frame.name}
        </div>
      </div>
    );
  },
);

export const FavoriteFramesResults: FC = memo(() => {
  const { flatData } = useFavoriteFrames();
  const frames = useMemo(() => (flatData || []).slice(0, 12), [flatData]);

  return (
    <div className="grid grid-cols-4 gap-1">
      {frames.map((frame) => (
        <FrameTile key={frameKeyExtractor(frame)} frame={frame} />
      ))}
      {frames.length === 0 && (
        <div className="col-span-4">
          <DefaultEmptyListView
            className="!bg-transparent"
            message="No frames added yet"
          />
        </div>
      )}
    </div>
  );
});

const FavoriteFrames: FC = () => {
  return (
    <AppFrameProvider>
      <Suspense>
        <FavoriteFramesContent />
      </Suspense>
    </AppFrameProvider>
  );
};

export const FavoriteFramesContent: FC = () => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const { flatData } = useFavoriteFrames();
  const hasFrames = (flatData || []).length > 0;
  const currentRoute = useCurrentRoute();

  const handlePromoClick = () => {
    trackEvent(SharedAmpEvent.ClickDiscoverFrames, {
      source: 'sidebar',
    });
    navigate({ to: 'frames', params: {} });
  };

  if (currentRoute?.routeName === 'frames' && !hasFrames) {
    return null;
  }

  return (
    <div className="mt-3 hidden rounded-lg px-4 py-3 bg-overlay-light mdlg:block">
      {hasFrames ? (
        <>
          <div className="flex items-center justify-between">
            <div className="px-2 py-1 text-lg font-semibold">Frames</div>
            <div className="mr-4 flex items-center space-x-4">
              <div
                onClick={() => navigate({ to: 'settingsFrames', params: {} })}
                className="cursor-pointer rounded text-muted"
              >
                <GearIcon size={16} />
              </div>
              <div
                onClick={() => navigate({ to: 'frames', params: {} })}
                className="cursor-pointer rounded text-muted"
              >
                <GlobeIcon size={16} />
              </div>
            </div>
          </div>
          <FavoriteFramesResults />
        </>
      ) : (
        <div className="cursor-pointer" onClick={handlePromoClick}>
          <div>
            <Image
              src="/~/images/DiscoverFramesHero.webp"
              alt="Discover Frames"
              className="w-full"
            />
          </div>
          <div className="mt-2 flex items-center justify-between">
            <div>
              <div className="text-lg font-semibold">Discover Frames</div>
              <div className="text-sm text-muted">View Frames in Action</div>
            </div>
            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-action-primary">
              <ArrowRightIcon size={16} className="text-white" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

FavoriteFrames.displayName = 'FavoriteFrames';

export { FavoriteFrames };
