import { datadogRum } from '@datadog/browser-rum';
import {
  AddFrame,
  Context,
  createIframeEndpoint,
  FrameClientEvent,
  FrameHost,
  HostEndpoint,
  useExposeToEndpoint,
  ViewProfile,
} from '@farcaster/frame-host';
import {
  CopyIcon,
  DiffAddedIcon,
  DiffRemovedIcon,
  KebabHorizontalIcon,
  SyncIcon,
  XIcon,
} from '@primer/octicons-react';
import * as Dialog from '@radix-ui/react-dialog';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import classNames from 'classnames';
import { ApiFrame, ApiUser } from 'farcaster-client-data';
import {
  frameAnalyticsProperties,
  resolveUsernameShort,
  SharedAmpEvent,
  useFrameDetails,
  useNonSuspenseFrameDetails,
  useTrackEvent,
} from 'farcaster-client-hooks';
import { Provider, RpcSchema } from 'ox';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAccount } from 'wagmi';

import { DropdownMenuItem } from '~/components/dropdownMenu/DropdownMenuItem';
import { DefaultButton } from '~/components/forms/buttons/DefaultButton';
import {
  IFrameLoader,
  IFrameLoaderRef,
} from '~/components/iframes/IFrameLoader';
import { Image } from '~/components/images/Image';
import { LaunchContext } from '~/contexts/AppFrameProvider';
import {
  FavoriteFrameProvider,
  useFavoriteFrame,
} from '~/contexts/FavoriteFrameProvider';
import {
  SignInProvider,
  useSignIn,
} from '~/contexts/Frame/Actions/SignInProvider';
import {
  useViewProfileAction,
  ViewProfileActionProvider,
} from '~/contexts/Frame/Actions/ViewProfileActionProvider';
import { useCurrentUser } from '~/hooks/data/useCurrentUser';
import { useExternalNavigate } from '~/hooks/navigation/useExternalNavigate';
import { trackError } from '~/utils/errorUtils';
import { toast } from '~/utils/toast';

type PrimaryButtonState = {
  text: string;
  disabled?: boolean;
  hidden?: boolean;
  loading?: boolean;
};

export type StandaloneFrameLaunchConfig = {
  type: 'standalone';
  url: string;
  name: string;
  splashImageUrl?: string;
  splashBackgroundColor?: string;
  author?: ApiUser;
};

export type ManifestFrameLaunchConfig = {
  type: 'manifest';
  domain: string;
};

export type FrameLaunchConfig =
  | ManifestFrameLaunchConfig
  | StandaloneFrameLaunchConfig;

type AppFrameModalProps<TConfig = FrameLaunchConfig> = {
  launchConfig: TConfig;
  context: LaunchContext;
  debug?: boolean;
  onClose: () => void;
};

export function AppFrameModal({ launchConfig, ...rest }: AppFrameModalProps) {
  switch (launchConfig.type) {
    case 'standalone':
      return <LaunchStandalone launchConfig={launchConfig} {...rest} />;
    case 'manifest':
      return <LaunchManifest launchConfig={launchConfig} {...rest} />;
  }
}

function LaunchStandalone({
  launchConfig,
  ...rest
}: AppFrameModalProps<StandaloneFrameLaunchConfig>) {
  return (
    <AppFrameDialog
      url={launchConfig.url}
      name={launchConfig.name}
      splashImageUrl={launchConfig.splashImageUrl}
      splashBackgroundColor={launchConfig.splashBackgroundColor}
      author={launchConfig.author}
      {...rest}
    />
  );
}

function LaunchManifest({
  launchConfig,
  ...rest
}: AppFrameModalProps<ManifestFrameLaunchConfig>) {
  const { data: frame } = useFrameDetails({ domain: launchConfig.domain });
  // const toast = useRootToast();
  // const pop = usePop();

  // useEffect(() => {
  //   if (!frame) {
  //     // toast.show(`No frame found for ${launchConfig.domain}`, {
  //     //   type: 'danger',
  //     // });
  //     rest.onClose();
  //   }
  // }, [frame, launchConfig.domain, pop, rest]);

  if (!frame) {
    return null;
  }

  return (
    <AppFrameDialog
      url={frame.homeUrl}
      name={frame.name}
      splashImageUrl={frame.splashImageUrl}
      splashBackgroundColor={frame.splashBackgroundColor}
      author={frame.author}
      {...rest}
    />
  );
}

function AppFrameDialog({
  url,
  name,
  splashBackgroundColor,
  splashImageUrl,
  author,
  debug = false,
  context,
  onClose,
}: {
  url: string;
  name: string;
  splashImageUrl?: string;
  splashBackgroundColor?: string;
  author?: ApiUser;
  context: LaunchContext;
  debug?: boolean;
  onClose: () => void;
}) {
  const { connectModalOpen } = useConnectModal();
  const { trackEvent, trackInternalEvent } = useTrackEvent();

  useEffect(() => {
    const analyticsProperties = frameAnalyticsProperties({
      frameUrl: url,
      frameName: name,
      author,
    });

    trackEvent(SharedAmpEvent.LaunchFrame, {
      ...analyticsProperties,
      from:
        context.type === 'notification'
          ? 'notification'
          : context.type === 'cast_embed'
            ? 'cast'
            : 'home',
    });

    trackInternalEvent({
      type: 'frame-launch',
      data: {
        frameDomain: analyticsProperties.frameDomain,
        frameUrl: analyticsProperties.frameUrl,
        frameName: analyticsProperties.frameName,
        authorFid: analyticsProperties.authorFid,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog.Root
      defaultOpen
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-10 animate-overlay-show bg-overlay" />
        <Dialog.Content
          className="fixed left-[50%] top-[50%] z-20 translate-x-[-50%] translate-y-[-50%] animate-content-show focus:outline-none"
          onPointerDownOutside={(e) => {
            // Don't close if the RainbowKit connect modal is displaying over the dialog
            if (connectModalOpen) {
              e.preventDefault();
            }
          }}
        >
          <div className="border-top border-left border-right relative w-[424px] overflow-hidden rounded-xl bg-app">
            <FavoriteFrameProvider>
              <SignInProvider>
                <ViewProfileActionProvider>
                  <AppFrameContent
                    url={url}
                    name={name}
                    splashBackgroundColor={splashBackgroundColor}
                    splashImageUrl={splashImageUrl}
                    author={author}
                    debug={debug}
                    context={context}
                    onClose={onClose}
                  />
                </ViewProfileActionProvider>
              </SignInProvider>
            </FavoriteFrameProvider>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

type AppFrameContentProps = {
  url: string;
  name: string;
  splashBackgroundColor?: string;
  splashImageUrl?: string;
  author?: ApiUser;
  debug?: boolean;
  context: LaunchContext;
  onClose: () => void;
};

function AppFrameContent({
  url,
  name,
  splashBackgroundColor,
  splashImageUrl,
  author,
  debug,
  context,
  onClose,
}: AppFrameContentProps) {
  const currentUser = useCurrentUser();
  const { trackEvent } = useTrackEvent();

  const [isKebabMenuOpen, setIsKebabMenuOpen] = useState(false);

  const iframeLoaderRef = useRef<IFrameLoaderRef>(null);
  const domain = useMemo(() => new URL(url).hostname, [url]);
  const { data: frame, refetch } = useNonSuspenseFrameDetails({ domain });

  const formDomain = React.useMemo(() => {
    return domain.replace(/^www\./, '');
  }, [domain]);

  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const minSplashTimer = useRef<Promise<void>>();
  if (minSplashTimer.current === undefined) {
    minSplashTimer.current = new Promise((resolve) => {
      setTimeout(resolve, 600);
    });
  }

  const refresh = useCallback(() => {
    if (iframeLoaderRef.current) {
      setShowSplashScreen(true);
      iframeLoaderRef.current.refresh();
    }
  }, []);

  const ready = useCallback(async () => {
    await minSplashTimer.current;
    setShowSplashScreen(false);
  }, []);

  const analyticsContext = useMemo(
    () => ({
      frameDomain: formDomain,
      frameName: name,
      authorFid: author?.fid,
    }),
    [author?.fid, formDomain, name],
  );

  const openUrl = useExternalNavigate();
  const handleOpenUrl = useCallback(
    (url: string) => {
      if (url.startsWith('https://')) {
        openUrl({ to: url, openInNewTab: true });
      }
    },
    [openUrl],
  );

  const { viewProfile } = useViewProfileAction();
  const handleViewProfile = useCallback<ViewProfile.ViewProfile>(
    async (params) => {
      trackEvent(SharedAmpEvent.FrameAction, {
        action: 'view profile',
        ...params,
      });
      await viewProfile(params);
    },
    [trackEvent, viewProfile],
  );

  const [primaryButton, setPrimaryButton] = useState<PrimaryButtonState | null>(
    null,
  );
  const handleSetPrimaryButton = useCallback((message: { text: string }) => {
    setPrimaryButton(message);
  }, []);
  const handlePrimaryButtonPress = () => {
    emit?.({ event: 'primary_button_clicked' });
  };

  // TODO: implement channel follows
  /* const handleFollowChannel = useCallback(() => {
    // ({ key }: { key: string }) => {
    //   return followChannel({ key });
    // },
  }, []); */

  const handleProviderRequest = useCallback(async () => {
    throw new Error('not implemented');
  }, []);

  const locationContext: Context.LocationContext | undefined = useMemo(
    () => (context.type !== 'dev_preview' ? context : undefined),
    [context],
  );

  const [endpoint, setEndpoint] = useState<HostEndpoint | undefined>(undefined);
  useEffect(() => {
    const iframe = iframeLoaderRef.current?.iframe;
    if (!iframe) {
      return;
    }

    setEndpoint(
      createIframeEndpoint({
        iframe,
        // targetOrigin: '*',
        targetOrigin: new URL(url).origin,
        debug,
      }),
    );
  }, [debug, url]);

  const emit = useMemo(() => endpoint?.emit, [endpoint?.emit]);

  const { confirmAddFavoriteFrame } = useFavoriteFrame();
  const promptedToAddFavoriteFrame = useRef(false);
  const handleAddFrame = useCallback(async () => {
    if (!frame) {
      emit?.({
        event: 'frame_add_rejected',
        reason: 'invalid_domain_manifest',
      });

      return Promise.reject(new AddFrame.InvalidDomainManifest());
    }

    if (frame.viewerContext?.favorited) {
      emit?.({
        event: 'frame_added',
        notificationDetails: frame.viewerContext?.notificationDetails,
      });

      return Promise.resolve<AddFrame.AddFrameResult>({
        notificationDetails: frame.viewerContext?.notificationDetails,
      });
    }

    // Avoid spamming by only allowing to ask the user once
    if (promptedToAddFavoriteFrame.current) {
      emit?.({ event: 'frame_add_rejected', reason: 'rejected_by_user' });
      return Promise.reject(new AddFrame.RejectedByUser());
    }

    promptedToAddFavoriteFrame.current = true;
    const result = await confirmAddFavoriteFrame({
      frame,
      emit,
      emitOnRejection: true,
      // We are already in a portal
      renderInPortal: false,
    });

    refetch();

    return result;
  }, [frame, confirmAddFavoriteFrame, refetch, emit]);

  const { signIn } = useSignIn();
  const handleSignIn = useCallback<FrameHost['signIn']>(
    async (options) => {
      const result = await signIn({
        name: frame?.name,
        domain,
        uri: url,
        options,
        // We are already in a portal
        renderInPortal: false,
      });

      return result;
    },
    [signIn, frame?.name, domain, url],
  );

  const sdk = useMemo<Omit<FrameHost, 'ethProviderRequestV2'>>(
    () => ({
      context: {
        user: {
          fid: currentUser.fid,
          username: currentUser.username,
          displayName: currentUser.displayName,
          pfpUrl: currentUser.pfp?.url,
          location: currentUser.profile.location,
        },
        location: locationContext,
        client: {
          clientFid: 9152,
          added: frame?.viewerContext?.favorited || false,
          notificationDetails: frame?.viewerContext?.notificationsEnabled
            ? frame?.viewerContext?.notificationDetails
            : undefined,
        },
      },
      close: onClose,
      ready: ready,
      setPrimaryButton: handleSetPrimaryButton,
      ethProviderRequest: handleProviderRequest,
      signIn: handleSignIn,
      openUrl: handleOpenUrl,
      addFrame: handleAddFrame,
      viewProfile: handleViewProfile,
      eip6963RequestProvider: () => {
        // no-op for now since extensions can announce themselves
        // in a browser context
      },
    }),
    [
      currentUser,
      locationContext,
      frame,
      onClose,
      ready,
      handleSetPrimaryButton,
      handleProviderRequest,
      handleOpenUrl,
      handleAddFrame,
      handleSignIn,
      handleViewProfile,
    ],
  );

  const { connector, addresses, isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();

  const [walletProvider, setWalletProvider] = useState<Provider.Provider>();
  const instrumentedProvider = useMemo(() => {
    if (!walletProvider) {
      return undefined;
    }

    return {
      on: walletProvider.on.bind(walletProvider),
      removeListener: walletProvider?.removeListener.bind(walletProvider),
      async request(parameters: RpcSchema.ExtractRequest<RpcSchema.Default>) {
        try {
          datadogRum.addAction(`frame:eth_provider:request`, {
            method: parameters.method,
            params: parameters.params,
            domain,
          });
        } catch {
          // no-op
        }

        try {
          switch (parameters.method) {
            case 'eth_signTransaction':
            case 'eth_sendTransaction': {
              trackEvent(SharedAmpEvent.RequestFrameEthTransaction, {
                method: parameters.method,
                ...analyticsContext,
              });
              const result = await walletProvider.request(parameters);
              trackEvent(SharedAmpEvent.ConfirmFrameEthTransaction, {
                method: parameters.method,
                ...analyticsContext,
              });
              return result;
            }
            case 'personal_sign':
            case 'eth_signTypedData_v4': {
              trackEvent(SharedAmpEvent.RequestFrameEthSignature, {
                method: parameters.method,
                ...analyticsContext,
              });
              const result = await walletProvider.request(parameters);
              trackEvent(SharedAmpEvent.ConfirmFrameEthSignature, {
                method: parameters.method,
                ...analyticsContext,
              });
              return result;
            }
            default:
              return await walletProvider.request(parameters);
          }
        } catch (e) {
          trackError(e);
          throw e;
        }
      },
    };
  }, [analyticsContext, domain, trackEvent, walletProvider]);

  const resolveConnectRef =
    useRef<(addresses: readonly `0x${string}`[]) => void>();

  useEffect(() => {
    if (isConnected && addresses && resolveConnectRef.current) {
      resolveConnectRef.current(addresses);
      resolveConnectRef.current = undefined;
    }
  }, [isConnected, addresses]);

  useEffect(() => {
    const setProvider = async () => {
      if (connector) {
        const provider = (await connector.getProvider()) as Provider.Provider;
        setWalletProvider(provider);
      } else {
        const defaultEmitter = Provider.createEmitter();
        const defaultProvider = Provider.from({
          ...defaultEmitter,
          async request(request) {
            switch (request.method) {
              case 'eth_accounts': {
                return [];
              }
              case 'eth_requestAccounts': {
                openConnectModal?.();

                return new Promise<readonly `0x${string}`[]>((resolve) => {
                  resolveConnectRef.current = resolve;
                });
              }
              default: {
                throw new Provider.DisconnectedError();
              }
            }
          },
        });
        setWalletProvider(defaultProvider);
      }
    };
    setProvider();
  }, [connector, openConnectModal]);

  useExposeToEndpoint({
    endpoint,
    sdk,
    frameOrigin: '*',
    // @ts-expect-error - cant figure out typing here
    ethProvider: instrumentedProvider,
    debug,
  });

  return (
    <>
      <FrameHeader
        name={name}
        author={author}
        domain={domain}
        frame={frame ?? undefined}
        locationContext={locationContext}
        isKebabMenuOpen={isKebabMenuOpen}
        setIsKebabMenuOpen={setIsKebabMenuOpen}
        onClose={onClose}
        refresh={refresh}
        emit={emit}
      />
      <div className="relative">
        {showSplashScreen && (
          <SplashScreen
            imageUrl={splashImageUrl}
            backgroundColor={splashBackgroundColor}
          />
        )}
        <AppFrameIFrame
          ref={iframeLoaderRef}
          url={url}
          splashBackgroundColor={splashBackgroundColor}
          disablePointerEvents={isKebabMenuOpen}
          primaryButton={primaryButton}
          handlePrimaryButtonPress={handlePrimaryButtonPress}
        />
      </div>
    </>
  );
}

type AppFrameIframeProps = {
  url: string;
  splashBackgroundColor?: string;
  disablePointerEvents?: boolean;
  primaryButton: PrimaryButtonState | null;
  handlePrimaryButtonPress: () => void;
};

export const AppFrameIFrame = forwardRef<IFrameLoaderRef, AppFrameIframeProps>(
  (
    {
      url,
      splashBackgroundColor,
      disablePointerEvents,
      primaryButton,
      handlePrimaryButtonPress,
    },
    ref,
  ) => {
    if (!url.startsWith('https://')) {
      return null;
    }

    return (
      <div className="flex h-[695px] flex-1 flex-col bg-muted">
        <IFrameLoader
          ref={ref}
          src={url}
          width={424}
          height={695}
          allow="microphone; camera; clipboard-write 'src'"
          sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
          className={classNames(
            splashBackgroundColor
              ? `bg-[${splashBackgroundColor}]`
              : 'scrollbar-hide bg-muted',
            disablePointerEvents && 'pointer-events-none',
          )}
        />
        {primaryButton && !primaryButton.hidden && (
          <div className="flex-none p-3 bg-app">
            <DefaultButton
              onClick={(e) => {
                e.stopPropagation();
                handlePrimaryButtonPress();
              }}
              disabled={primaryButton.disabled}
              isLoading={primaryButton.loading}
              className="h-[48px] w-full px-2 !text-lg"
            >
              {primaryButton.text}
            </DefaultButton>
          </div>
        )}
      </div>
    );
  },
);

function SplashScreen({
  imageUrl,
  backgroundColor,
}: {
  imageUrl?: string;
  backgroundColor?: string;
}) {
  return (
    <div
      className="absolute inset-0 z-10 flex h-full w-[424px] items-center justify-center"
      style={{ backgroundColor: backgroundColor ?? '#ffffff' }}
    >
      {imageUrl && (
        <div className="mt-[-88px]">
          <Image src={imageUrl} alt="Splash" className="h-[88px] w-[88px]" />
        </div>
      )}
    </div>
  );
}

function FrameHeader({
  name,
  author,
  domain,
  frame,
  locationContext,
  isKebabMenuOpen,
  setIsKebabMenuOpen,
  onClose,
  refresh,
  emit,
}: {
  name: string;
  author?: ApiUser;
  domain: string;
  frame?: ApiFrame;
  locationContext?: Context.LocationContext;
  isKebabMenuOpen: boolean;
  setIsKebabMenuOpen: (open: boolean) => void;
  onClose: () => void;
  refresh: () => void;
  emit: ((event: FrameClientEvent) => void) | undefined;
  debug?: boolean;
}) {
  const { confirmAddFavoriteFrame, confirmRemoveFavoriteFrame } =
    useFavoriteFrame();
  const { refetch } = useNonSuspenseFrameDetails({ domain });

  const handleAddFrame = useCallback(async () => {
    if (!frame) {
      return;
    }

    setIsKebabMenuOpen(false);
    try {
      await confirmAddFavoriteFrame({
        frame,
        emit,
        emitOnRejection: false,
        // We are already in a portal
        renderInPortal: false,
      });

      refetch();
    } catch (e) {
      if (e instanceof AddFrame.RejectedByUser) {
        throw e;
      }

      toast({ message: 'Something went wrong', type: 'error' });
      throw e;
    }
  }, [emit, confirmAddFavoriteFrame, frame, refetch, setIsKebabMenuOpen]);

  const handleRemoveFrame = useCallback(() => {
    if (frame) {
      setIsKebabMenuOpen(false);
      confirmRemoveFavoriteFrame({ frame, emit, renderInPortal: false });
      refetch();
    }
  }, [frame, setIsKebabMenuOpen, confirmRemoveFavoriteFrame, emit, refetch]);

  const frameShareUrl = useMemo(() => {
    if (locationContext?.type === 'cast_embed') {
      return locationContext.embed;
    }

    if (frame) {
      const url = new URL(
        'https://' + window.location.host + '/~/frames/launch',
      );
      url.searchParams.set('domain', frame.domain);
      return url.href;
    }
  }, [locationContext, frame]);

  return (
    <div className="flex h-[60px] items-center justify-between px-4 py-3 bg-elevated-nohover">
      <div
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
        className="cursor-pointer"
      >
        <XIcon className="h-6 w-6 text-faint" />
      </div>

      <div className="mx-4 max-w-[280px] flex-1 text-center">
        {author ? (
          <>
            <div className="font-semibold">{name}</div>
            <div className="text-xs text-faint">
              built by {resolveUsernameShort(author)}
            </div>
          </>
        ) : (
          <div>{domain}</div>
        )}
      </div>

      <DropdownMenu.Root
        open={isKebabMenuOpen}
        onOpenChange={setIsKebabMenuOpen}
        modal={false}
      >
        <DropdownMenu.Trigger>
          <div className="cursor-pointer rounded-full px-1 text-muted hover:bg-gray-200">
            <KebabHorizontalIcon />
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          side="bottom"
          sideOffset={21}
          align="end"
          alignOffset={-15}
          className="z-20 w-52 divide-y divide-[#efefef] overflow-hidden rounded-xl shadow-xl bg-app border-default dark:divide-[#4c3a4e80]"
          onClick={(e) => e.stopPropagation()}
          onCloseAutoFocus={(e) => e.preventDefault()}
        >
          {!!frameShareUrl && (
            <DropdownMenuItem
              name="Copy link"
              icon={<CopyIcon size="small" />}
              onSelect={(e) => {
                e.preventDefault();
                toast({ message: 'Link copied to clipboard', type: 'info' });
                navigator.clipboard.writeText(frameShareUrl);
                setIsKebabMenuOpen(false);
              }}
            />
          )}
          <DropdownMenuItem
            name="Reload page"
            icon={<SyncIcon size="small" />}
            onSelect={(e) => {
              e.preventDefault();
              setIsKebabMenuOpen(false);
              refresh();
            }}
          />
          {frame && (
            <>
              {frame.viewerContext?.favorited ? (
                <DropdownMenuItem
                  name="Remove Frame"
                  icon={<DiffRemovedIcon size="small" />}
                  onSelect={async (e) => {
                    e.preventDefault();
                    handleRemoveFrame();
                  }}
                  className="text-danger"
                />
              ) : (
                <DropdownMenuItem
                  name="Add Frame"
                  icon={<DiffAddedIcon size="small" />}
                  onSelect={async (e) => {
                    e.preventDefault();
                    await handleAddFrame();
                  }}
                />
              )}
            </>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
}
