import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { getNextPageCursor } from 'farcaster-client-data';
import { useMemo } from 'react';

import { useFarcasterApiClient } from '../../../../providers';
import { frameKeyExtractor } from '../../../../utils';
import { useFlatStandardizedPaginatedResults } from '../../helpers';
import { useBatchMergeIntoGloballyCachedFrame } from '../frameDetails';
import { buildFavoriteFramesFetcher } from './buildFavoriteFramesFetcher';
import { buildFavoriteFramesKey } from './buildFavoriteFramesKey';

export function useFavoriteFrames() {
  const { apiClient } = useFarcasterApiClient();
  const batchMergeIntoGlobalCache = useBatchMergeIntoGloballyCachedFrame();

  const result = useSuspenseInfiniteQuery({
    initialPageParam: undefined,
    queryKey: buildFavoriteFramesKey(),
    queryFn: buildFavoriteFramesFetcher({
      apiClient,
      params: { limit: 20 },
      batchMergeIntoGlobalCache,
    }),
    getNextPageParam: getNextPageCursor,
    staleTime: 0,
  });

  const flatData = useFlatStandardizedPaginatedResults({
    data: result.data,
    uniqBy: frameKeyExtractor,
  });

  return useMemo(
    () => ({
      ...result,
      flatData,
    }),
    [flatData, result],
  );
}
