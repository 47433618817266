import { BellIcon, DeviceMobileIcon } from '@primer/octicons-react';
import * as Dialog from '@radix-ui/react-dialog';
import { ApiFrame } from 'farcaster-client-data';
import React, { useMemo } from 'react';

import { DefaultButton } from '~/components/forms/buttons/DefaultButton';
import { Image } from '~/components/images/Image';

interface ConfirmFavoriteFrameDialogProps {
  frame: ApiFrame;
  onClose: () => void;
  onConfirm: () => void;
  renderInPortal?: boolean;
}

export const ConfirmFavoriteFrameDialog: React.FC<
  ConfirmFavoriteFrameDialogProps
> = ({ frame, onClose, onConfirm, renderInPortal = true }) => {
  if (renderInPortal) {
    return (
      <Dialog.Root
        defaultOpen
        onOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-10 animate-overlay-show bg-overlay" />
          <Dialog.Content className="fixed left-[50%] top-[50%] z-20 translate-x-[-50%] translate-y-[-50%] animate-content-show focus:outline-none">
            <ConfirmFavoriteFrameDialogInner
              frame={frame}
              onClose={onClose}
              onConfirm={onConfirm}
            />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  return (
    <>
      <div
        className="absolute inset-x-0 bottom-0 top-[60px] animate-overlay-show bg-black/30 dark:bg-white/30"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      />

      <div
        className="absolute inset-x-4 bottom-4 z-50"
        onClick={(e) => e.stopPropagation()}
      >
        <ConfirmFavoriteFrameDialogInner
          frame={frame}
          onClose={onClose}
          onConfirm={onConfirm}
        />
      </div>
    </>
  );
};

interface ConfirmFavoriteFrameDialogInnerProps {
  frame: ApiFrame;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmFavoriteFrameDialogInner: React.FC<
  ConfirmFavoriteFrameDialogInnerProps
> = ({ frame, onClose, onConfirm }) => {
  const permissions = useMemo(() => {
    const permissions = [
      {
        name: 'Add to Warpcast',
        icon: <DeviceMobileIcon size={20} />,
      },
    ];

    if (frame.supportsNotifications) {
      permissions.push({
        name: 'Enable notifications',
        icon: <BellIcon size={20} />,
      });
    }

    return permissions;
  }, [frame.supportsNotifications]);

  return (
    <div className="mx-auto w-full max-w-[424px] animate-frame-action-content-show rounded-xl border p-6 pb-4 bg-app border-default">
      <div className="flex w-full flex-col items-center space-y-4">
        <div className="relative">
          <Image
            src={frame.iconUrl}
            alt={frame.name}
            className="h-[72px] w-[72px] rounded-lg"
          />
          <div className="border-background absolute -right-2 -top-2 flex h-8 w-8 items-center justify-center rounded-full border-2 bg-[#E5E0F3]">
            <span className="text-xl text-[#7C65C1]">+</span>
          </div>
        </div>
        <h3 className="text-center text-xl font-semibold">Add {frame.name}</h3>
      </div>

      <div className="mt-4 w-full space-y-3 rounded-lg p-3 bg-faint">
        {permissions.map(({ name, icon }) => (
          <div key={name} className="flex items-center space-x-2">
            <div className="flex h-8 w-8 items-center justify-center">
              {icon}
            </div>
            <div>{name}</div>
          </div>
        ))}
      </div>

      <div className="mt-6 flex w-full flex-row space-x-2">
        <DefaultButton
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          className="h-[40px] w-[180px]"
          variant="inverted"
        >
          Cancel
        </DefaultButton>
        <DefaultButton
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
          className="h-[40px] w-[180px]"
          variant="normal"
        >
          Confirm
        </DefaultButton>
      </div>
    </div>
  );
};
