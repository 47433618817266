import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useFarcasterApiClient } from '../../../../providers';
import { buildFrameDetailsFetcher } from './buildFrameDetailsFetcher';
import { buildFrameDetailsKey } from './buildFrameDetailsKey';
import {
  useGloballyCachedFrame,
  useMergeIntoGloballyCachedFrame,
} from './framesGlobalCache';

export const useFrameDetails = ({ domain }: { domain: string }) => {
  const { apiClient } = useFarcasterApiClient();
  const mergeIntoGlobalCache = useMergeIntoGloballyCachedFrame();

  const result = useSuspenseQuery({
    queryKey: buildFrameDetailsKey({ domain }),
    queryFn: buildFrameDetailsFetcher({
      domain,
      apiClient,
      mergeIntoGlobalCache,
    }),
    staleTime: 0,
  });

  const frame = useGloballyCachedFrame(result.data);

  return useMemo(
    () => ({
      ...result,
      data: frame,
    }),
    [frame, result],
  );
};

export const useNonSuspenseFrameDetails = ({ domain }: { domain: string }) => {
  const { apiClient } = useFarcasterApiClient();
  const mergeIntoGlobalCache = useMergeIntoGloballyCachedFrame();

  const result = useQuery({
    queryKey: buildFrameDetailsKey({ domain }),
    queryFn: buildFrameDetailsFetcher({
      domain,
      apiClient,
      mergeIntoGlobalCache,
    }),
    staleTime: 0,
  });

  const frame = useGloballyCachedFrame(result.data);

  return useMemo(
    () => ({
      ...result,
      data: frame,
    }),
    [frame, result],
  );
};
